<template>
<div>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <div class="text-h4 text-center">Update User</div>
          <v-form ref="form"
                v-model="valid"
                lazy-validation
            >
            <v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-alert type="error" v-if="addErrResp">{{addErrResp}}</v-alert>
                  <v-text-field
                    v-model="formData.name"
                    label="Name"
                    type="text"
                    required
                    :rules="rules.nameRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="3">
                  <v-text-field v-model="formData.email" label="Email" type="email" :rules="rules.emailRules"></v-text-field>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field v-model="formData.contact_number" label="Contact No" type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.old_password" label="Old Password" type="password"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.password" label="New Password" type="password"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.password_confirmation" label="Retype New Password" type="password"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="pb-0">Loan Management Permissions</v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.people" label="Access People" color="success" hide-details></v-switch>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.report" label="Access Reports" color="success" hide-details></v-switch>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-switch v-model="formData.transaction" label="Access Transactions" color="success" hide-details class="mt-0"></v-switch>
                </v-col>
              </v-row>
               <v-row class="justify-center">
                <v-col cols="12" lg="6" class="pb-0">Investment Management Permissions</v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.investment" label="Access Investments" color="success" hide-details></v-switch>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-switch v-model="formData.return_of_investment" label="Access ROI" color="success" hide-details></v-switch>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-switch v-model="formData.project" label="Access Projects" color="success" hide-details class="mt-0"></v-switch>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <!-- <v-btn color="secondary" class="px-10 mr-5" @click="$router.push('/people')">Veiw List</v-btn> -->
                  <v-btn color="primary" class="px-10" @click="updatePeople()" :disabled="addLoading">Update</v-btn><br>
                   <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="addLoading"
                    ></v-progress-circular>
                </v-col>
              </v-row> 
              <v-row>
                <v-col>
                  <v-overlay :value="getLoading" color="white">
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                  </v-overlay>
                </v-col>
              </v-row> 
            </v-container>
          </v-form>
        </v-card>  
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import {get_user_details,update_user} from "@api/users"
export default {
  data(){
    return {
      valid:true,
      formData:{
        name:"",
        email:"",
        contact_number:"",
        old_password:"",
        password:"",
        password_confirmation:"",
        transaction:false,
        people: false,
        report: false,
        project:false,
        investment: false,
        return_of_investment:false,
      },
      rules:{
        nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      },
      addErrResp:'',
      addLoading:false,
      getLoading:false,
    }
  },
  mounted() {
    this.getUserDetails(this.$route.params.id)
  },
  methods:{
    getUserDetails(id){
      this.getLoading= true;
      get_user_details(id).then(resp=>{
        this.getLoading= false;
        if(resp.status==='Success'){
          this.setUserDetails(resp.data)
        }else{
          this.addErrResp= resp.errors;
        }
      })
    },
    setUserDetails(data){
      this.formData={
        name:data.name,
        email:data.email,
        contact_number:data.contact_number,
        password:"",
        transaction:data.permission.transaction===1?true:false,
        people: data.permission.people===1?true:false,
        report: data.permission.report===1?true:false,
        project:data.permission.project===1?true:false,
        investment: data.permission.investment===1?true:false,
        return_of_investment:data.permission.return_of_investment===1?true:false,
      }
    },
    updatePeople(){
      this.formData.people=this.formData.people===true?1:0;
      this.formData.transaction=this.formData.transaction===true?1:0;
      this.formData.report=this.formData.report===true?1:0;
      this.formData.project=this.formData.project===true?1:0;
      this.formData.investment=this.formData.investment===true?1:0;
      this.formData.return_of_investment=this.formData.return_of_investment===true?1:0;
      this.addLoading=true;
      this.addErrResp=''
      update_user({id:this.$route.params.id,data:this.formData}).then(resp=>{
        this.addLoading=false;
        if(resp.status==='Success'){
          this.$toastr.s(resp.message);
        }else{
          this.addErrResp=resp.errors;
        }
      });
    }
  }
}
</script>

<style>

</style>